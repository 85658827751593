// Import necessary dependencies and components
import React, { useMemo, useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import {
  Row,
  Col,
  Button,
  ButtonGroup,
  Dropdown,
  Badge,
} from "react-bootstrap";
import ClickBack from "../../../components/buttons/ClickBack";
import DynamicTableFournisseur from "../../../components/DynamicTableFournisseur";

import IconShow  from "../../../images/fi_eye.png";
import IconEdit  from "../../../images/edit.png";
import Download from "../../../images/download.svg";

import { fetchAllFournisseurCommandes } from "../CommandesFournisseurs/DataService";
import { urlPdf } from "../../../utils/ApiConfig";
import { format } from "date-fns";

// Define the functional component
function ListCommandesAllFournisseur(props) {
  // State to hold user data
  const [commandesData, setCommandesData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [nbr, setNbr] = useState(1);
  const [nbElement, setNbElement] = useState(10);

  // Cette fonction pour afficher liste des fournisseurs
  const fetchData = async () => {
    try {
      setLoading(true);
      const data = await fetchAllFournisseurCommandes(nbr, searchTerm, nbElement);
      console.log("data:", data.data);
      setCommandesData(data);
      console.log("commandesData?.links:",data?.links)
    } catch (error) {
      console.error("Error lors de affiche liste fournisseur:", error);
    } finally {
      setLoading(false);
    }
  };

    // Function to handle user role change
    const handleNombreElementParPage = (nbElement) => {
      setNbElement(nbElement); // Set the selected value
      switch (nbElement) {
        case 10:
          setNbElement(10);
          console.log("nbElement:",nbElement)
          break;
        case 25:
          setNbElement(25);
          console.log("nbElement:",nbElement)
          break;
        case 50:
          setNbElement(50);
          console.log("nbElement:",nbElement)
          break;
        case 100:
          setNbElement(100);
          console.log("nbElement:",nbElement)
          break;
        default:
          setNbElement(10);
          console.log("nbElement:",nbElement)
      }
    };

  // useEffect pour charger automatiquement les données lors du montage du composant
  useEffect(() => {
    fetchData(nbr);
  }, [nbr, searchTerm, nbElement]); // Empty dependency array means it will run once when the component mounts

  // Colonnes mémorisées pour le composant DynamicTable
  const columns = useMemo(() => {
    return [
      { Header: "N° de commande", accessor: "id" },
      { Header: "N° de bon de livraison", accessor: "NBonLivraison" },
      { Header: "N° de téléphone client", accessor: "TelClient" },
      {
        Header: "Date et Heure de commande",
        accessor: "DateCommande",
        Cell: ({ value }) => (
          <span>
            {value ? format(new Date(value), "dd-MM-yyyy HH:mm") : ""}
          </span>
        ),
      },
      { Header: " Raison Social", accessor: "fournisseur_profil.user_profil.RaisonSocial" },
      { Header: "Adresse pick-up", accessor: "AdressePickUp"},
      {Header: "Adresse mail fournisseur",accessor: "fournisseur_profil.email" },
      {
        Header: "Statut de commande",
        accessor: "StatuCommande",
        Cell: ({ value }) => {
          let statusLabel = "";
          let badgeClass = "bg-secondary"; // Default badge color
      
          switch (value) {
            case 1:
              statusLabel = "En cours de livraison";
              badgeClass = "bg-warning";
              break;
            case 2:
              statusLabel = "Livré";
              badgeClass = "bg-success";
              break;
            case 3:
              statusLabel = "En cours de préparation";
              badgeClass = "bg-info";
              break;
            case 6:
              statusLabel = "Annulé";
              badgeClass = "bg-danger";
              break;
            default:
              statusLabel = "En cours de préparation";
              badgeClass = "bg-info";
          }
      
          return (
            <Badge className={badgeClass}>
              {statusLabel}
            </Badge>
          );
        }
      },     
      {
        Header: "Statut de paiement",
        accessor: "StatuPaiement",
        Cell: ({ value }) => {
          let statusLabel = "";
          let badgeClass = "bg-secondary"; // Default badge color
      
          switch (value) {
            case 1:
              statusLabel = "Facturé";
              badgeClass = "bg-success";
              break;
            case 2:
              statusLabel = "Non facturé";
              badgeClass = "bg-danger";
              break;
            default:
              statusLabel = "Non facturé";
              badgeClass = "bg-danger";
          }
      
          return (
            <Badge className={badgeClass}>
              {statusLabel}
            </Badge>
          );
        }
      },   
      {
        Header: "Actions",
        Cell: ({ value, row }) => (
          <div>
            <a href={`/commande-fournisseur/show/${row.original.id}`} className="p-2">
              <img src={IconShow} width="20px" alt="Voir" />
            </a>
            <a
              href={`/commande-fournisseur/edit/${row.original.id}`}
              className="p-2"
            >
              <img src={IconEdit} width="20px" alt="Modifier" />
            </a>
            <a href={`${urlPdf}/Bordereau/${row?.original.Bordereau}`} target="_blank" rel="Bordereaux">
                <img src={Download} alt="Download"  />
            </a>
          </div>
        ),
      },
    ].filter(Boolean); // Filter out any falsey values
  }, [nbr]); // Add dependencies if needed

  // Render loading message while data is being fetched
  if (!commandesData) {
    return (
      <Container>
        <p>Chargement...</p>
      </Container>
    );
  }

  // Render the component
  return (
    <Container>
      <div className="commandesFournisseursList">
        {/* Breadcrumb navigation */}
        <p className="h6">Accueil / Commandes Fournisseurs </p>

        {/* Header and filters */}
        <Row className="pt-4 pb-2">
          <Col style={{ display: "flex", alignItems: "center" }}>
            <ClickBack />
            {/* Display dynamic title */}
            <h1 className="p-1">Commandes Fournisseurs</h1>
          </Col>
          {/* add button */}
          <Col
            className="d-flex justify-content-end align-items-center"
            style={{ alignContent: "right" }}
          >
            <a className="btn btn-primary" href="/commande-fournisseur/add">
              Ajouter Commande
            </a>
          </Col>
        </Row>
        {/* Header and filters */}
        <Row className="pt-4 pb-2 align-items-center">
          <Col >
            {/* Display nombre d'element par page dans le dynamic table */}
            <Dropdown as={ButtonGroup} className="p-2 mb-2">
              <Dropdown.Toggle id="dropdown-basic" className="UserTypeDropdown">
               Nombre d'element à afficher: {nbElement} {/* Afficher la valeur actuelle */}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleNombreElementParPage(10)}>
                  10
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleNombreElementParPage(25)}>
                  25
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleNombreElementParPage(50)}>
                  50
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleNombreElementParPage(100)}>
                  100
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col
            xs
            lg="2"
            className="d-flex justify-content-end align-items-center"
            style={{ alignContent: "right" }}
          ></Col>
        </Row>

        {/* Render the DynamicTable component */}
        {loading ? (
          // Display loader while data is being fetched
          <p className="lead">Chargement...</p>
        ) : (
          <DynamicTableFournisseur
            columns={columns}
            setSearchTerm={setSearchTerm}
            data={commandesData?.data}
            link={commandesData?.links}
            next_page_url={commandesData?.next_page_url}
            prev_page_url={commandesData?.prev_page_url}
            last_page={commandesData?.last_page}
            setNbr={setNbr}
            nbr={nbr}
            total={commandesData?.total}
            name="CommandesAllfournisseurs"
          />
        )}
      </div>
    </Container>
  );
}

// Export the component as the default export
export default ListCommandesAllFournisseur;
