import React, { useEffect, useState } from "react";
import { useTable, useFilters, usePagination, useSortBy } from "react-table";
import arrowUpDown from "../images/arrowUpDown.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";


import { fetchDeliveryTowerData } from "../pages/DeliveryTower/DataService";

import { Col } from "react-bootstrap";
import { fetchAllBySupplier } from "../pages/Fournisseur/CompteFournisseur/DataService";
import { useParams } from "react-router-dom";

export default function DynamicTablecommandesFournisseur({
  columns,
  data,
  link,
  setNbr,
  nbr,
  total,
  last_page,
  setSearchTerm,
  searchTerm,
  filtreDate,
  name,
}) {
  const [tableData, setTableData] = useState(data);
  const [query, setQuery] = useState("");
  const [filterInput, setFilterInput] = useState("");
  const [selectedRows, setSelectedRows] = useState({});
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    setFilter,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
     handleSelectAllChange,
    state: { pageIndex, pageSize },
    setPageSize,
  } = useTable(
    {
      columns,
      data: tableData,
      initialState: { pageIndex: 0, pageSize: 50 },
    },
    useFilters,
    useSortBy,
    usePagination
  );
  const { idFournisseur } = useParams()

  const handleFilterChange = async () => {

   console.log("query:",query);
    setSearchTerm(filterInput);
    setNbr(1);
    let data = [];
    if (name === "factures_fournisseurs") {
      //'bo/getFactureByFrounisseur',idFournisseur,nbr,filtreDate,searchTerm
      data = await fetchAllBySupplier('bo/getFactureByFrounisseur',idFournisseur,nbr,filtreDate, searchTerm);
    }

    setTableData(data?.data);
  };

  const handleChangeTerm = (event) => {
    const value = event.target.value;
    setQuery(value);
    setFilterInput(value);
  };

  const handleChangeTermDate = async (event) => {
    const value = event.target.value;
    setQuery(value);
    setFilterInput(value);
    let query = "&search_date=" + value;
    setSearchTerm(query);
    setNbr(1);
    let data = await fetchDeliveryTowerData(nbr, query);
    setTableData(data?.data);
  };

  const renderPageNumbers = () => {
    const maxPageButtons = 5;
    const currentPage = pageIndex + 1;
    const totalPages = pageOptions.length;
    const startPage = Math.max(1, currentPage - Math.floor(maxPageButtons / 2));
    const endPage = Math.min(startPage + maxPageButtons - 1, totalPages);
    const pageNumbers = [];
    for (let i = 1; i <= link.length; i++) {
      pageNumbers.push(
        <strong
          key={i}
          className={`page-tab ${pageIndex === i - 1 ? "active" : ""}`}
          onClick={() => nextPage(i - 1)}
        >
          {i}
        </strong>
      );
    }
    return pageNumbers;
  };

  useEffect(() => {
    setTableData(data);
  }, [data, nbr]);

  // const handleCheckboxChange = (rowId) => {
  //   setSelectedRows((prevSelectedRows) => ({
  //     ...prevSelectedRows,
  //     [rowId]: !prevSelectedRows[rowId],
  //   }));
  // };

  const isRowSelected = (rowId) => selectedRows[rowId] || false;

  return (
    <div className="dynamic-table-container">
      <div className="pt-2 pb-3">
        <div className="custom-search d-flex text-end ">
         <Col >
          {name === "factures_fournisseurs" && (
            <>
              <input
                type="text"
                className="custom-search-input form-control"
                placeholder="Rechercher"
                value={filterInput}
                onChange={handleChangeTerm}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    handleFilterChange();
                  }
                }}
              />
              <button
                className="custom-search-botton"
                type="submit"
                onClick={handleFilterChange}
              >
                <FontAwesomeIcon icon={faSearch} size="1x" color="#fff" />
              </button>
            </>
          )}
          </Col>
        </div>
      </div>
     
      <table {...getTableProps()} className="dynamic-table">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <img
                        src={arrowUpDown}
                        alt="Down Arrow"
                        width="15px"
                        style={{ marginLeft: "4px" }}
                      />
                    ) : (
                      <img
                        src={arrowUpDown}
                        alt="Up Arrow"
                        width="15px"
                        style={{ marginLeft: "4px" }}
                      />
                    )
                  ) : (
                    ""
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, rowIndex) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                style={{
                  backgroundColor: rowIndex % 2 === 0 ? "white" : "#F2F5F8",
                }}
              >
                {/* Conditionally render checkbox */}               
                  {/* <td>
                    <input
                      type="checkbox"
                      onChange={() => handleCheckboxChange(row.id)}
                      checked={isRowSelected(row.id)}
                    />
                  </td> */}
                
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className="container mb-5">
        <div className="row">
          <div className="col-10">
            <p className="h6">Total de {total} lignes </p>
          </div>
          <div className="col-2 d-flex justify-content-end align-items-center">
            {nbr > 1 && (
              <strong
                onClick={() => {
                  setNbr(nbr - 1);
                }}
              >
                Précédent
              </strong>
            )}

            {Array.from({ length: last_page }, (_, index) => index + 1).map(
              (pageNum) => {
                if (pageNum <= 3) {
                  return (
                    <strong
                      key={pageNum}
                      className={`page-tab ${nbr === pageNum ? "active" : ""}`}
                      onClick={() => {
                        setNbr(pageNum);
                      }}
                    >
                      {pageNum}
                    </strong>
                  );
                } else if (pageNum > last_page - 3) {
                  return (
                    <strong
                      key={pageNum}
                      className={`page-tab ${nbr === pageNum ? "active" : ""}`}
                      onClick={() => {
                        setNbr(pageNum);
                      }}
                    >
                      {pageNum}
                    </strong>
                  );
                } else if (pageNum >= nbr - 1 && pageNum <= nbr + 1) {
                  return (
                    <strong
                      key={pageNum}
                      className={`page-tab ${nbr === pageNum ? "active" : ""}`}
                      onClick={() => {
                        setNbr(pageNum);
                      }}
                    >
                      {pageNum}
                    </strong>
                  );
                } else if (pageNum === last_page - 3) {
                  return (
                    <strong key={pageNum} className="page-tab">
                      ...
                    </strong>
                  );
                }
                return null;
              }
            )}

            {nbr < last_page && (
              <strong
                onClick={() => {
                  setNbr(nbr + 1);
                }}
              >
                Suivant
              </strong>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
