import React , { useState, useEffect }from 'react';
import { Modal } from 'react-bootstrap';
import "bootstrap-icons/font/bootstrap-icons.css";
import doneIcon from '../../images/ok.gif';
const ModalValidation = ({ show, onClose ,text}) => {
  const [countdown, setCountdown] = useState(2); // Adjust the countdown time in seconds

  useEffect(() => {
    let timer;
    
    if (show && countdown > 0) {
      timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000); // Update the countdown every second
    } else if (countdown === 0) {
      onClose(); // Close the modal when the countdown reaches 0
    }

    return () => clearTimeout(timer); // Clear the timer on component unmount or modal close

  }, [show, countdown, onClose]);

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Body className="text-center">
        <img src={doneIcon} alt="Done Icon" width="200" className="mx-auto d-block" />
        <p>{text}</p>
        <p>La fenêtre se fermera dans {countdown} secondes</p>
      </Modal.Body>
    </Modal>
  );
};

export default ModalValidation;
