// Sidebar.js
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Nav, Col, Row, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Image from "react-bootstrap/Image";
import SidebarItems from "./SidebarItems";
import Logo from "../images/Frame 1321317892.png";
import "../style/sidebar.css"; // Import the CSS file
import { useAuth } from "../utils/AuthContext";

function Sidebar({ defaultActive, user }) {
  const { logout } = useAuth();
  const location = useLocation();
  const lastActiveIndexString = localStorage.getItem("lastActiveIndex");
  const lastActiveIndex = Number(lastActiveIndexString);
  const [activeIndex, setActiveIndex] = useState(
    lastActiveIndex || defaultActive
  );

  function changeActiveIndex(newIndex) {
    localStorage.setItem("lastActiveIndex", newIndex);
    setActiveIndex(newIndex);
  }

  function getPath(path) {
    return path.charAt(0) !== "/" ? "/" + path : path;
  }

  useEffect(() => {
    const activeItem = SidebarItems.findIndex((item) => {
      return (
        item.route === getPath(location.pathname) ||
        (item.subcategories &&
          item.subcategories.find(
            (sub) => sub.route === getPath(location.pathname)
          ))
      );
    });
    changeActiveIndex(activeItem);
  }, [location]);

  const [subMenuOpen, setSubMenuOpen] = useState({});

  const toggleSubMenu = (index) => {
    setSubMenuOpen((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  return (
    <Container className="scrollable-container" style={{height:'100%'}}>
      <Row>
        <Col className="sidePadding">
          <Nav className="flex-column">
            <Link to="/">
              <img className="myLogo" src={Logo} alt="LogoMarion" />
            </Link>

            {SidebarItems.map((item, index) => (
              <React.Fragment key={item.name}>
                <div>
                  {item.subcategories ? (
                    <div
                      onClick={() => toggleSubMenu(index)}
                      className={`sidebar-item ${
                        index === activeIndex ? "active" : ""
                      }`}
                    >
                      <p>
                        <img
                          className="CutomeSideBarIcon"
                          src={item.icon}
                          alt={item.name}
                        />
                        {item.name}
                      </p>
                      <span className="dropdown-arrow">
                        {subMenuOpen[index] ? "▲" : "▼"}
                      </span>
                    </div>
                  ) : (
                    <Link to={item.route}>
                      <div
                        className={`sidebar-item ${
                          index === activeIndex ? "active" : ""
                        }`}
                      >
                        <p>
                          <img
                            className="CutomeSideBarIcon"
                            src={item.icon}
                            alt={item.name}
                          />
                          {item.name}
                        </p>
                      </div>
                    </Link>
                  )}
                  {item.subcategories && subMenuOpen[index] && (
                    <div className="subcategory-container">
                      {item.subcategories.map((subcategory, subIndex) => (
                        <Link
                          key={subIndex}
                          to={subcategory.route}
                          className={`sidebar-item ${
                            getPath(subcategory.route) ===
                            getPath(location.pathname)
                              ? "active"
                              : ""
                          }`}
                        >
                          <p>{subcategory.name}</p>
                        </Link>
                      ))}
                    </div>
                  )}
                </div>
              </React.Fragment>
            ))}

            <Row className="blurBG justify-content-center" style={{ marginTop: '100%'}}>
              <Col
                className="mb-4 ml-4 d-flex align-items-end"
                style={{ paddingLeft: "20px" }}
              >
                <Image
                  src="https://questhowth.ie/wp-content/uploads/2018/04/user-placeholder.png"
                  alt="User Profile"
                  roundedCircle
                  style={{
                    width: "40px",
                    marginRight: "10px",
                    marginBottom: "3px",
                  }}
                />
                <div>
                  <h4>
                    {user ? `${user.prenom} ${user.nom}` : "Default Username"}
                  </h4>
                  <Nav.Link onClick={logout}>
                    <h5>Déconnexion</h5>
                  </Nav.Link>
                </div>
              </Col>
            </Row>
          </Nav>
        </Col>
      </Row>
    </Container>
  );
}

export default Sidebar;
