import axios from "axios";
import { jwtDecode } from "jwt-decode";



// Additional export for the API PDF URL
const urlPdf = "https://dev.api.marion-materiaux.com"; // Dev  
//const urlPdf = "https://recette.api.marion-materiaux.com"; // Recette 
//const urlPdf = "https://api.marion-materiaux.com"; // Prod

const instance = axios.create({
baseURL: "https://dev.api.marion-materiaux.com/api", // Dev
//baseURL: "https://recette.api.marion-materiaux.com/api", // Recette  
//baseURL: 'https://api.marion-materiaux.com/api', // Prod

});

const authToken = localStorage.getItem("authToken");

function checkTokenAndRedirect(authToken) {
  // Get the current pathname
const currentPath = window.location.pathname;
  const allowedPaths = [
    "/login",
    "/reinitialiser_mot_de_passe",
    "/changer_mot_de_passe",
    "/fournisseur/renitialise-mot-de-passe"
  ];
  // if (!isTokenValid(authToken)) {
  //   if (window.location.pathname !== "/login" 
  //     && window.location.pathname !== "/fournisseur/Renitialise-mot-de-passe/:token" 
  //     && window.location.pathname !== "/reinitialiser_mot_de_passe" 
  //     && window.location.pathname !== "/changer_mot_de_passe") {
  //     window.location.href = "/login";
  //   }
  // }
  if (!isTokenValid(authToken)) {
    // Check if the current path is not in the allowed paths
    const isAllowedPath = allowedPaths.some(path => currentPath.startsWith(path));
  
    if (!isAllowedPath) {
      window.location.href = "/login";
    }
  }
}

function isTokenValid(token) {
  try {
    const decodedToken = jwtDecode(token);
    if (decodedToken.exp < Date.now() / 1000) {
      return false;
    }
    return true;
  } catch (error) {
    return false;
  }
}

checkTokenAndRedirect(authToken);

instance.interceptors.request.use(
  (config) => {
    const authToken = localStorage.getItem("authToken");
    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);




export { urlPdf };

export default instance;
