import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../utils/AuthContext';

const LoginServices = () => {
  const history = useHistory();
  const [userData, setUserData] = useState(null);

  const { login, register } = useAuth();

  const [loginState, setLoginState] = useState({
    email: '',
    password: '',
  });

  const [forgotPasswordState, setForgotPasswordState] = useState({
    email: '',
  });

  const [registrationState, setRegistrationState] = useState({
    username: '',
    email: '',
    password: '',
  });

  const [status, setStatus] = useState({
    error: null,
    message: '',
    loading: false,
  });

  const setEmail = (email) => {
    setLoginState((prevLoginState) => ({ ...prevLoginState, email }));
  };

  const setPassword = (password) => {
    setLoginState((prevLoginState) => ({ ...prevLoginState, password }));
  };

  const setForgotPasswordEmail = (email) => {
    setForgotPasswordState((prevForgotPasswordState) => ({ ...prevForgotPasswordState, email }));
  };

  const setRegistrationData = (field, value) => {
    setRegistrationState((prevRegistrationState) => ({ ...prevRegistrationState, [field]: value }));
  };

  const setStatusError = (error) => {
    setStatus((prevStatus) => ({ ...prevStatus, error }));
  };

  const setStatusMessage = (message) => {
    setStatus((prevStatus) => ({ ...prevStatus, message }));
  };

  const setStatusLoading = (loading) => {
    setStatus((prevStatus) => ({ ...prevStatus, loading }));
  };

  const handleForgotPassword = async () => {
    try {
      setStatusError(null);
      setStatusLoading(true);

      // Implement your forgot password logic here
      // For example, make an API call to send a reset email

      // Assuming you have successfully sent the reset email
      setStatusMessage('Reset email sent successfully.');

      return { success: true };
    } catch (error) {
      console.error('Forgot password failed:', error.message);
      setStatusError('Forgot password failed. Please try again.');
      return { success: false, error: error.message };
    } finally {
      setStatusLoading(false);
    }
  };

  const handleRegister = async () => {
    try {
      setStatusError(null);
      setStatusLoading(true);

      // Call the register function from AuthContext
      await register({
        username: registrationState.username,
        email: registrationState.email,
        password: registrationState.password,
      });

      // If registration is successful, you can perform additional actions here if needed
      // For example, logging in the user or navigating to a different screen
      // ...

      setStatusMessage('Registration successful.');
      return { success: true };
    } catch (error) {
      console.error('Registration failed:', error.message);
      setStatusError('Registration failed. Please check your information.');
      return { success: false, error: error.message };
    } finally {
      setStatusLoading(false);
    }
  };

  const handleLogin = async () => {
    try {
      setStatusError(null); // Clear any previous error
      setStatusLoading(true); // Set loading to true
  
      // Call the login function from AuthContext
      const response = await login(loginState.email, loginState.password);

      // Check if login is successful
      if (response) {
        // Extract user data from the response
        const { user, token } = response.data.data;
        console.log('user',user)
        // Check if the user is an admin
        if (user.role === '5') {  
          // Update user state if needed (assuming you have a setUser function)
          setUserData(user);
          // Save the token to local storage or a secure storage method
        //  localStorage.setItem('token', token);
          localStorage.setItem('userData', JSON.stringify(user));

          // Redirect to the dashboard or the latest page
          const latestPage = localStorage.getItem('latestPage') || '/dashboard';
          history.push(latestPage);
  
          // Return the user data or other relevant information
          return { success: true, userData };
        } 
        else if(user.role === '6' && user.status === 1 ){
          setUserData(user);
          // Save the token to local storage or a secure storage method
        //  localStorage.setItem('token', token);
          localStorage.setItem('userData', JSON.stringify(user));
          history.push('/fournisseur/commande');
        }
        else {
          // Handle non-admin login attempt
          setStatusError('Accès refusé. Seuls les administrateurs peuvent se connecter');
          return { success: false, error: 'Access denied' };
        }
      } else {
        // Handle unsuccessful login
        setStatusError(`La connexion a échoué. Veuillez vérifier vos informations d'identification.`);
        return { success: false, error: 'Login failed' };
      }
    } catch (error) {
      console.error('Login failed:', error.message);
      setStatusError(`La connexion a échoué. Veuillez vérifier vos informations d'identification.`);
      return { success: false, error: error.message };
    } finally {
      setStatusLoading(false); // Set loading to false regardless of success or failure
    }
  };

  return {
    loginState,
    setEmail,
    setPassword,
    handleLogin,
    forgotPasswordState,
    setForgotPasswordEmail,
    handleForgotPassword,
    registrationState,
    setRegistrationData,
    handleRegister,
    status,
    setStatusError,
    setStatusMessage,
    setStatusLoading,
  };
};

export default LoginServices;
