import React, { useEffect, useState } from "react";
import { useTable, useFilters, usePagination, useSortBy } from "react-table";
import arrowUpDown from "../images/arrowUpDown.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import {fetchFournisseurFiltred ,fetchAllFournisseur} from "../pages/Fournisseur/CompteFournisseur/DataService";

import { Col, Row } from "react-bootstrap";
export default function DynamicTableFournisseur({
  columns,
  data,
  link,
  setNbr,
  nbr,
  total,
  last_page,
  setSearchTerm,
  searchTerm,
  name,
}) {
  const [tableData, setTableData] = useState(data);
  const [query, setQuery] = useState("");
  const [filterInput, setFilterInput] = useState("");
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    setFilter,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state: { pageIndex, pageSize },
    setPageSize,
  } = useTable(
    {
      columns,
      data: tableData,
      initialState: { pageIndex: 0, pageSize: 50 },
    },
    useFilters,
    useSortBy,
    usePagination
  );
  //changer l'etat de la terme à chercher
  const handleChangeTerm = (event) => {
    const value = event.target.value;
    setQuery(value);
    console.log("query:",query)
    setFilterInput(value); // Met à jour la valeur de l'input
  };
  //Efectuer la recherche avec la terme stocker dans query

  const handleFilterChange = async () => {
    let query = "" ;
    if(name == "fournisseurs"){
      query = `search[value]=${filterInput}`;
    }else if(name == "CommandesAllfournisseurs"){
       query = `search=${filterInput}`;
    }
    setSearchTerm(query);
    setNbr(1);
    const data = await fetchAllFournisseur(1, query);
    setTableData(data?.data);
};


  const renderPageNumbers = () => {
    const maxPageButtons = 5;
    const currentPage = pageIndex + 1;
    const totalPages = pageOptions.length;
    const startPage = Math.max(1, currentPage - Math.floor(maxPageButtons / 2));
    const endPage = Math.min(startPage + maxPageButtons - 1, totalPages);
    const pageNumbers = [];
    for (let i = 1; i <= link.length; i++) {
      pageNumbers.push(
        <strong
          key={i}
          className={`page-tab ${pageIndex === i - 1 ? "active" : ""}`}
          onClick={() => nextPage(i - 1)}
        >
          {i}
        </strong>
      );
    }
    return pageNumbers;
  };
  useEffect(() => {
    setTableData(data);
  }, [data, nbr, query]);
  return (
    <div className="dynamic-table-container">
      <div className="pt-2 pb-3">
        <div className="custom-search d-flex text-end ">   
         <Col>
         <input
                type="text"
                className="custom-search-input form-control"
                placeholder="Rechercher"
                value={filterInput}
                onChange={handleChangeTerm}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    handleFilterChange();
                  }
                }}
              />
              <button
                className="custom-search-botton"
                type="submit"
                onClick={handleFilterChange}
              >
                <FontAwesomeIcon icon={faSearch} size="1x" color="#fff" />
              </button>
         </Col>     
        </div>
      </div>
      <span className="d-flex align-items-center pb-3">
      </span>
      <table {...getTableProps()} className="dynamic-table">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <img
                        src={arrowUpDown}
                        alt="Down Arrow"
                        width="15px"
                        style={{ marginLeft: "4px" }}
                      />
                    ) : (
                      <img
                        src={arrowUpDown}
                        alt="Up Arrow"
                        width="15px"
                        style={{ marginLeft: "4px" }}
                      />
                    )
                  ) : (
                    ""
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, rowIndex) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                style={{
                  backgroundColor: rowIndex % 2 === 0 ? "white" : "#F2F5F8",
                }}
              >
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="container mb-5">
        <div className="row">
          <div className="col-7">
            <p className="h6">Total de {total} lignes </p>
          </div>
          <div className="col d-flex justify-content-end align-items-center">
            {/* <button onClick={previousPage} disabled={!canPreviousPage} className="btn btn-outline-primary CustomPagination">Précedent</button> */}
            {/* {renderPageNumbers()} */}
            {/* <button onClick={nextPage} disabled={!canNextPage} className="btn btn-outline-primary CustomPagination">Suivant</button> */}

            {/* Bouton "Précédent" */}
            {nbr > 1 && (
              <strong
                onClick={() => {
                  setNbr(nbr - 1);
                }}
              >
                Précédent
              </strong>
            )}

            {/* Affichage des numéros de page */}
            {Array.from({ length: last_page }, (_, index) => index + 1).map(
              (pageNum) => {
                // Afficher les trois premières pages
                if (pageNum <= 3) {
                  return (
                    <strong
                      key={pageNum}
                      className={`page-tab ${nbr === pageNum ? "active" : ""}`}
                      onClick={() => {
                        setNbr(pageNum);
                      }}
                    >
                      {pageNum}
                    </strong>
                  );
                }
                // Afficher les trois dernières pages
                else if (pageNum > last_page - 3) {
                  return (
                    <strong
                      key={pageNum}
                      className={`page-tab ${nbr === pageNum ? "active" : ""}`}
                      onClick={() => {
                        setNbr(pageNum);
                      }}
                    >
                      {pageNum}
                    </strong>
                  );
                }
                // Afficher la page actuelle et les pages voisines
                else if (pageNum >= nbr - 1 && pageNum <= nbr + 1) {
                  return (
                    <strong
                      key={pageNum}
                      className={`page-tab ${nbr === pageNum ? "active" : ""}`}
                      onClick={() => {
                        setNbr(pageNum);
                      }}
                    >
                      {pageNum}
                    </strong>
                  );
                }
                // Afficher des points pour les autres pages
                else if (pageNum === 4 || pageNum === last_page - 3) {
                  return <strong key={pageNum}>...</strong>;
                }
              }
            )}

            {/* Bouton "Suivant" */}
            {nbr < last_page && (
              <strong
                onClick={() => {
                  setNbr(nbr + 1);
                }}
              >
                Suivant
              </strong>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
