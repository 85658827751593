// Import necessary dependencies and components
import React, { useMemo, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import { Row, Col, Modal, Form, Button, Alert } from 'react-bootstrap';
import ClickBack from '../../components/buttons/ClickBack';
import DynamicTable from '../../components/DynamicTable';
import moment from 'moment';
import { fetchBrandsData, updateBrandStatus, updateBrandData, saveBrandData } from './DataService';
import IconEdit from '../../images/edit.png';
import IconShow from "../../images/fi_eye.png"
import IconTrash from "../../images/trash.png";
import { urlPdf } from '../../utils/ApiConfig';

// Define the functional component
function ListScreen(props) {
  // Initialize necessary hooks
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState('add'); // Add a state to track modal mode
  const [brandData, setBrandData] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedBrandID, setSelectedBrandID] = useState(null);
  const [loading, setLoading] = useState(false);
  const [imageName, setImageName] = useState(''); // State for image name
  const [imageFile, setImageFile] = useState(null); // State for image file
  const [error, setError] = useState(null); // State for error message
  const [nbr, setNbr] = useState(1);
  const [searchTerm ,setSearchTerm] =useState('');

  const [newBrandData, setNewBrandData] = useState({
    name: '',
    slug: '',
    description: ''
  }); // State for new brand data

  // Function to handle image upload
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setImageFile(file);
    setImageName(file.name);
  };

  // Function to handle input changes for new or selected brand data
  const handleNewBrandChange = (event) => {
    const { name, value } = event.target;
    if (modalMode === 'add') {
      // For adding a new brand
      setNewBrandData(prevData => ({
        ...prevData,
        [name]: value
      }));
    } else {
      // For editing an existing brand
      setSelectedBrandID(prevRow => ({
        ...prevRow,
        original: {
          ...prevRow.original,
          [name]: value
        }
      }));
    }
  };

  // Function to handle modal option click
  const handleModalOptionClick = (subcategoryID) => {
    setSelectedBrandID(subcategoryID);
    setShowModal(true);
    setModalMode('edit'); // Set modal mode to edit
  };

  // Function to open add brand modal
  const handleOpenAddBrandModal = () => {
    setShowModal(true);
    setModalMode('add'); // Set modal mode to add
  };

  // Function to update category data
  const handleUpdateBrand = async () => {
    // Implementation for updating brand data
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('name', selectedBrandID.original.name);
      formData.append('slug', selectedBrandID.original.slug);
      formData.append('description', selectedBrandID.original.description);
      formData.append('id', selectedBrandID.original.id);
      formData.append('image', imageFile);
      // Validation for required fields
      if (!selectedBrandID.original.name || !selectedBrandID.original.slug || !selectedBrandID.original.description || !imageFile) {
        throw new Error('Tous les champs sont obligatoires.');
      }

      const response = await updateBrandData(formData);
      if (response.status === 200) {
        handleCloseModal();
        history.go(0);
      } else {
        throw new Error(`Failed to update brand. Status: ${response.status}`);
      }
    } catch (error) {
      setError(error.message);
      console.error('Error updating brand:', error);
    } finally {
      setLoading(false);
    }
  };

  // Function to handle brand deletion
  const handleBrandDelete = async (categoryId) => {
    // Implementation for deleting brand
    try {
      const response = await updateBrandStatus(categoryId);
      if (response.status === 200) {
        handleCloseModal();
        history.go(0);
      } else {
        throw new Error(`Failed to delete brand. Status: ${response.status}`);
      }
    } catch (error) {
      setError(error.message);
      console.error('Error deleting brand:', error);
    }

  };

  // Function to add new brand
  const handleAddBrand = async () => {
    // Implementation for adding new brand
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('name', newBrandData.name);
      formData.append('slug', newBrandData.slug);
      formData.append('description', newBrandData.description);
      formData.append('image', imageFile);

      // Validation for required fields
      if (!newBrandData.name || !newBrandData.slug || !newBrandData.description || !imageFile) {
        throw new Error('Tous les champs sont obligatoires.');
      }

      const response = await saveBrandData(formData);
      if (response.status === 201) {
        handleCloseModal();
        history.go(0);
      } else {
        throw new Error(`Failed to add brand. Status: ${response.status}`);
      }
    } catch (error) {
      setError(error.message);
      console.error('Error adding brand:', error);
    } finally {
      setLoading(false);
    }
  };

  // Function to close modal
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedBrand(null);
    setNewBrandData({
      name: '',
      slug: '',
      description: ''
    });
    setError(null); // Clear error message when modal is closed
  };

  // Function to fetch brand data
  const fetchData = async () => {
    try {
      setLoading(true);
      const data = await fetchBrandsData(nbr,searchTerm);
      console.log("data:",data)
      setBrandData(data);
    } catch (error) {
      setError('Error fetching Brand data.');
      console.error('Error fetching Brand data:', error);
    } finally {
      setLoading(false);
    }
  };

  // useEffect to automatically load data when the component mounts
  useEffect(() => {
    fetchData(nbr);
  }, [nbr,searchTerm]);

  // Memoized columns for the DynamicTable component
  const columns = useMemo(
    () => [
      { Header: 'ID', accessor: 'id' },
      { Header: 'Titre', accessor: 'name' },
      {
        Header: 'Actions',
        accessor: 'original.id',
        Cell: ({ value, row }) => (
          <div>
            {/* Edit icon with a click handler */}
            <a className='p-2' onClick={() => handleModalOptionClick(row)}>
              <img src={IconEdit} width='20px' alt='Modifier' />
            </a>
            <a
              href="#"
              className="p-2"
              onClick={() => handleBrandDelete(row.original.id)}
            >
              <img src={IconTrash} width="20px" alt="Supprimer" />
            </a>
          </div>
        ),
      },
    ],
    []
  );

  // Render loading message while data is being fetched
  if (!brandData) {
    return <Container><p>Chargement...</p></Container>;
  }

  // Render the component
  return (
    <Container>
      <div className='BrandList'>
        {/* Breadcrumb navigation */}
        <p className='h6'>Accueil / Marques</p>

        {/* Header and filters */}
        <Row className='pt-4 pb-2'>
          <Col style={{ display: 'flex', alignItems: 'center' }}>
            <ClickBack />
            {/* Display dynamic title */}
            <h1 className='p-1'>Marques</h1>
          </Col>
          <Col xs lg='2' className='d-flex justify-content-end align-items-center' style={{ alignContent: 'right' }}>
            <Button variant='primary' onClick={handleOpenAddBrandModal}>Ajouter Marque</Button>
          </Col>
        </Row>

        {/* Render the DynamicTable component */}
        {loading ? (
          // Display loader while data is being fetched
          <p className='lead'>Chargement...</p>
        ) : (
          <DynamicTable 
           columns={columns}
           setSearchTerm={setSearchTerm}
           data={brandData?.data} 
           link={brandData?.links}
           next_page_url={brandData?.next_page_url}
           prev_page_url={brandData?.prev_page_url}
            setNbr={setNbr}
            nbr={nbr}
            total={brandData?.total}
            last_page={brandData?.last_page}
            name="marque"
          />
        )}
      </div>

      {/* Bootstrap Modal */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{modalMode === 'add' ? 'Ajouter une nouvelle marque' : 'Modifier/supprimer une catégorie : ' + (selectedBrandID ? selectedBrandID.original.id : '')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form>
            <Form.Group controlId='formBrandName' className='mb-2'>
              <Form.Label>Nom de la marque</Form.Label>
              <Form.Control
                type='text'
                placeholder='Saisir le nom de la marque'
                name='name'
                value={modalMode === 'edit' ? selectedBrandID?.original.name : newBrandData.name}
                onChange={handleNewBrandChange}
                required
              />
            </Form.Group>
            <Form.Group controlId='formBrandSlug' className='mb-2'>
              <Form.Label>Slug</Form.Label>
              <Form.Control
                type='text'
                placeholder='Saisir le nom de la marque'
                name='slug'
                value={modalMode === 'edit' ? selectedBrandID?.original.slug : newBrandData.slug}
                onChange={handleNewBrandChange}
                required
              />
            </Form.Group>
            <Form.Group controlId='formBrandDesc' className='mb-2'>
              <Form.Label>Description</Form.Label>
              <Form.Control
                as='textarea'
                rows={3}
                placeholder='Saisir la description de la marque'
                name='description'
                value={modalMode === 'edit' ? selectedBrandID?.original.description : newBrandData.description}
                onChange={handleNewBrandChange}
                required
              />
            </Form.Group>
            <Form.Group controlId='formBrandImage' className='mb-2'>
              <Form.Label>Image</Form.Label>
              <Form.Control
                type='file'
                accept='image/*'
                onChange={handleImageUpload}
                required                
            />
               {modalMode === 'edit' ? selectedBrandID?.original.image :imageName}
              <div>
              {modalMode === 'edit' ?
                 <img src ={`${urlPdf}/${selectedBrandID?.original.image}`} />:
                 ""
              }
              </div>

            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseModal}>Fermer</Button>
          <Button variant='primary' onClick={modalMode === 'add' ? handleAddBrand : handleUpdateBrand}>
            {modalMode === 'add' ? 'Ajouter' : 'Modifier'}
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

// Export the component as the default export
export default ListScreen;
