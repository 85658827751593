import React, {  useState } from "react";
import AddFicheFournisseur from "../Component/AddFicheFournisseur";
import { CreateFournisseur } from "./DataService";
import { useHistory } from "react-router-dom";
import ConfirmationModal from "../../../components/modal/ConfirmationModal";
import ModalValidation from "../../../components/modal/ModalValidation";

function AddFournisseur() {
  const [apiError, setApiError] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [actionType, setActionType] = useState("");
  const [showOkModal, setShowOkModal] = useState(false); // State for OkModal
  const [formData, setFormData] = useState(null); // Store form data before confirmation
  const history = useHistory();

  // Function to handle closing the confirmation modal
  const handleCloseConfirmationModal = () => {
    setShowConfirmationModal(false);
  };

  // Function to handle closing the OkModal
  const handleOkModalClose = () => {
    setShowOkModal(false);
    // Optionally redirect or perform other actions
    history.push("/fournisseurs");
  };

  // Function to handle form submission
  const handleSubmit = (values) => {
    // Store form data and open confirmation modal
    const formData = new FormData();
    formData.append("email", values.email);
    formData.append("etat", values.etat);
    formData.append("adresse", values.address);
    formData.append("altitudeFournisseur", values.altitudeFournisseur);
    formData.append("longitudeFournisseur", values.longitudeFournisseur);
    formData.append("montantLivraison", values.montantHT);
    formData.append("commission", values.commission);
    // formData.append("tva", values.tva);
    formData.append("RaisonSocial", values.RaisonSocial);
    // formData.append("montantTTC", values.montantTTC);

    setFormData(formData); // Store form data before confirming
    setActionType("add");
    setShowConfirmationModal(true); // Open the confirmation modal
  };

  // Function to handle the actual API call after confirmation
  const handleConfirm = async () => {
    try {
      const response = await CreateFournisseur(formData);  
      setShowConfirmationModal(false); // Close confirmation modal
      setShowOkModal(true); // Show success modal
    } catch (error) {
      if (error.response && error.response.status === 422) {
        const apiErrorMessage = error.response.data.error;
        setApiError(apiErrorMessage);
      } else {
        console.error("There was an error adding the fournisseur!", error);
      }
    }
  };

  return (
    <>
      <AddFicheFournisseur
        handleSubmit={handleSubmit}
        apiErrors={{ email: apiError }}
      />
      <ConfirmationModal
        show={showConfirmationModal}
        onClose={handleCloseConfirmationModal}
        onConfirm={handleConfirm} // Call handleConfirm after confirmation
        actionType={actionType}
      />
      <ModalValidation
        show={showOkModal}
        onClose={handleOkModalClose}
        onConfirm={handleOkModalClose}
        text={"Un fournisseur ajouté avec succès"}
      />
    </>
  );
}

export default AddFournisseur;
