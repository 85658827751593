import React, { useEffect, useState } from "react";
import Logo from "../../../images/Frame 1321317892.png";
import Eye from "../../../images/fi_eye.svg";
import EyeClosed from "../../../images/fi_eye-off.svg";
import ArrowRight from "../../../images/fi_arrow-right.svg";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import { verifyToken } from "../demandeCommandeFournisseurs/DataService";

const FirstConnexion = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [valid, setValid] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const params = useParams();
  // Regex pattern for password validation
  const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W).{8,}$/;
  const history=useHistory;
  const validatePasswords = () => {
    if (!passwordPattern.test(password)) {
      setError(
        "Le mot de passe doit contenir au moins 8 caractères, une majuscule, un chiffre et un caractère spécial."
      );
      return false;
    }
    if (password !== confirmPassword) {
      setError("Les mots de passe ne correspondent pas.");
      return false;
    }
    setError(""); // Clear error if everything is valid
    return true;
  };
  
  
  const VerifyTokenBefore= async(token)=>{
    console.log('im hereeeee')
    const res= await verifyToken({ token });
    setValid(res.valid)
  }
  useEffect(()=>{VerifyTokenBefore(params.token)},[])
  const handleSubmit = (event) => {
    event.preventDefault();
    
    if (validatePasswords()) {
      const data = {
        token: params.token,
        password: password,
        password_confirmation: confirmPassword,
      };
      
      axios
        .post("https://dev.api.marion-materiaux.com/api/updatePasswordF", data)
        .then((res) => {
          console.log("res", res.data);
  
          // Save data to localStorage
          localStorage.setItem('authToken', res?.data?.token);
          localStorage.setItem('userData', JSON.stringify(res?.data?.user));

          localStorage.setItem('idUser', res?.data?.user?.id);
          localStorage.setItem('role', res?.data?.user?.role);
  
          // Check if response contains an error field and handle it
          if (res.data.error) {
            setError(res.data.error);
          } else {
            // Redirect to another route if no error
            window.location.href='/fournisseur/commande';
          }
        })
        .catch((err) => {
          // Log detailed error object to understand the issue better
          console.error("Full error object:", err);
  
          // Set error message from response if available
          const errorMessage = err.response?.data?.error || "An unexpected error occurred.";
          setError(errorMessage);
  
          console.log("err", errorMessage);
        });
    }
  };
  

  const handleToggle = (type) => {
    if (type === "confirm") {
      setShowConfirmPassword(!showConfirmPassword);
    } else {
      setShowPassword(!showPassword);
    }
  };

  return (
    <div className="container-fluid vh-100 d-flex align-items-center justify-content-center mainBgColor">
      {valid && 
      <div className="container-sm blurBG p-4 rounded" style={{ width: 500 }}>
        {/* Logo link */}
        <img
          className="img-fluid float-left"
          src={Logo}
          alt="LogoMarion"
          width="50%"
        />
        <hr />
        <h1 className="text-left mainColor">Définissez votre mot de passe</h1>
        <form onSubmit={handleSubmit}>
          <div className="password-input-container form-floating mb-3 flex-grow-1">
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              name="password"
              placeholder=""
              onChange={(event) => {
                setPassword(event.target.value);
                setError("");
              }}
              className="form-control"
            />
            <label htmlFor="password">Mot de passe</label>
            <div className="password-toggle-icon">
              {showPassword ? (
                <img
                  src={Eye}
                  className="password-icon"
                  alt="password show"
                  onClick={() => handleToggle("password")} // Corrected here
                />
              ) : (
                <img
                  src={EyeClosed}
                  className="password-icon"
                  alt="password hide"
                  onClick={() => handleToggle("password")} // Corrected here
                />
              )}
            </div>
          </div>
          <div className="password-input-container form-floating mb-3 flex-grow-1">
            <input
              type={showConfirmPassword ? "text" : "password"}
              id="confirmpassword"
              name="confirmpassword"
              placeholder=""
              onChange={(event) => {
                setConfirmPassword(event.target.value); // Correct state
                setError("");
              }}
              className="form-control"
            />
            <label htmlFor="confirmpassword">Confirmer le mot de passe</label>{" "}
            {/* Corrected label for accessibility */}
            <div className="password-toggle-icon">
              {showConfirmPassword ? (
                <img
                  src={Eye}
                  className="password-icon"
                  alt="password show"
                  onClick={() => handleToggle("confirm")} // Corrected here
                />
              ) : (
                <img
                  src={EyeClosed}
                  className="password-icon"
                  alt="password hide"
                  onClick={() => handleToggle("confirm")} // Corrected here
                />
              )}
            </div>
          </div>
          <div>
            <button type="submit" className="btn btn-primary btn-lg w-100">
              Je me connecte{" "}
              <img src={ArrowRight} className="ml-8" alt="arrow right" />
            </button>
          </div>
          <span className="text-danger pt-5">{error}</span>
        </form>
      </div>
      }
    </div>
  );
};

export default FirstConnexion;
