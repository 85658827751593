import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Autocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import ErrorIcon from '../../../images/fi_alert-triangle.svg';
import Logo from "../../../images/Frame 1321317892.png";
import { useHistory } from 'react-router-dom';
import { addDemandeCommandeFournisseur} from './DataService';

const FormSupplier = () => {

  const history = useHistory();
  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Adresse e-mail invalide').required('L\'adresse e-mail est obligatoire'),
    clientName: Yup.string().required('Le nom du client est obligatoire'),
    steName: Yup.string().required("Le raison social de l'entreprise est obligatoire"),
    telephone: Yup.string().matches(/^[0-9]{10}$/, 'Le numéro de téléphone doit comporter 10 chiffres').required('Le numéro de téléphone est obligatoire'),
    comment: Yup.string(),
    pickupAdr: Yup.string().required("Adresse Pick up est requise"),
    address: Yup.string().required("Adresse de Livraison est requise"),
    steClient: Yup.string().required("Raison Sociale est required"),
    deliveryDateTime: Yup.string().required('La date et l\'heure de livraison sont obligatoires'),
    deliveryReceipt: Yup.mixed().required('Le reçu de livraison au format PDF est obligatoire').test(
      'fileSize',
      'Fichier trop volumineux',
      value => value && value.size <= 25000000
    ),
  });
  

  const [address, setAddress] = useState("");
  const [pickupAdr, setPickUpAdr] = useState("");
  const [file, setFile] = useState(null);
  const [Error, setError] = useState("");
  const [ErrorPickUp, setErrorPickUp] = useState("");
  const [deliveryLatLng, setDeliveryLatLng] = useState(null);
  const [pickupLatLng, setPickupLatLng] = useState(null);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [emailStorage, setEmailStorage] = useState(userData?.email);
  const [raisonSocialStorage, setRaisonSocialStorage] = useState(userData?.user_profil?.RaisonSocial);          
  console.log("userData.RaisonSocial:",userData)                                   
  const HandleSave = async(values) => {
    console.log('Form submitted with values:', values,deliveryLatLng); // Log form values
    // setError('')
    // setErrorPickUp('');

    // if (!address || !deliveryLatLng?.lat) {
    //   setError('Adresse de livraison requise');
    //   return;
    // }
    
    // if (!pickupAdr || !pickupLatLng?.lat) {
    //   setErrorPickUp('Adresse de pick-up requise');
    //   return;
    // }

    // const data = {
    //   delivery: {
    //     address: address,
    //     latitude: deliveryLatLng.lat,
    //     longitude: deliveryLatLng.lng,
    //   },
    //   pickup: {
    //     address: pickupAdr,
    //     latitude: pickupLatLng.lat,
    //     longitude: pickupLatLng.lng,
    //   },
      // Include other form data here as needed
    // };
    const userData = JSON.parse(localStorage.getItem("userData"));
        const myFormData=new FormData()
        myFormData.append('file',file)
        myFormData.append('id_fournisseur',userData.id)
        myFormData.append('adressPickUp',pickupAdr)
        myFormData.append('email',values.email)
        myFormData.append('adresseLivraison',address)
        myFormData.append('dateLivraison',values.deliveryDateTime)
        myFormData.append('tel',values.telephone)
        myFormData.append('raisonSocial',values.steName)
        myFormData.append('raisonSocialClient',values.steClient)
        myFormData.append('nomClient',values.clientName)
        myFormData.append('longitudePickUp',pickupLatLng.lng)
        myFormData.append('altitudePickUp',pickupLatLng.lat)
        myFormData.append('altitudeLivraison',deliveryLatLng.lat)
        myFormData.append('longitudeLivraison',deliveryLatLng.lng)
        myFormData.append('commantaire',values.comment)
      const response = await addDemandeCommandeFournisseur(myFormData, history);

    // Add your save logic here
  };

  const handleChangeAddress = (address, type, setFieldValue) => {
      
   
    if (type === "pickup") {
      setPickUpAdr(address);
     // setErrorPickUp('');
     setFieldValue("pickupAdr", address);
    } else {
      setAddress(address);
      setFieldValue("address", address);
      //setError('')
    }
  };

  const handleSelect = async (address, type, setFieldValue) => {
   

    if (type === "pickup") {
      setFieldValue("pickupAdr", address);
      setPickUpAdr(address);
    } else {
      setFieldValue("address", address);
      setAddress(address);
    }

    try {
      const results = await geocodeByAddress(address);
      const addressComponents = results[0].address_components;

      // Extract necessary address components
      const postalCode = addressComponents.find(component => 
        component.types.includes('postal_code')
      );
      const street = addressComponents.find(component => 
        component.types.includes('route')
      );

      if (postalCode && street) {
        console.log('Address is complete:', postalCode.long_name, street.long_name);

        const latLng = await getLatLng(results[0]);
        if (latLng) {
          if (type === "pickup") {
            setPickupLatLng(latLng);
          } else {
            setDeliveryLatLng(latLng);
          }
        } else {
          console.error("Error: Unable to get latitude and longitude");
        }
      } else {
        if (type === "pickup") {
          setErrorPickUp('Adresse sélectionnée incomplète. Code postal ou rue manquant.');
          setPickupLatLng(null);


        } else {
          setError('Adresse sélectionnée incomplète. Code postal ou rue manquant.');
          setDeliveryLatLng(null);

        }
        console.error('Error: Incomplete address. Missing postal code or street.');
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const searchOptions = {
    componentRestrictions: { country: ['fr'] },
    types: ['address']
  };
  const currentDate = new Date().toISOString().slice(0, 16);
  const logout=()=>{
    localStorage.clear()
    window.location.href="/login"
  }
useEffect(()=>{},[emailStorage, raisonSocialStorage])
  return (
    <>
    
    <div className="container p-5">
    <div className="header row">
        <div className="col-md-6">  <img src={Logo} alt="logo marion" width="100px" /></div>
        <div className="col-md-6 d-flex justify-content-end"><button onClick={logout} className='btn btn-secondary'>Se déconnecter</button></div>   
    </div>
      <h2 className='pt-5'>Commande fournisseur</h2>
      <Formik
        initialValues={{
          email: emailStorage,
          clientName: "",
          steName: raisonSocialStorage,
          steClient: "",
          telephone: "",
          comment: "",
          deliveryDateTime: "",
          deliveryReceipt: "",
          pickupAdr:"",
          address: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          HandleSave(values);
          setSubmitting(false);
        }}
      >
        {({ errors, touched, setFieldValue, isSubmitting, handleSubmit,handleBlur ,values}) => (
          <Form onSubmit={handleSubmit} className="mt-40">
            <div className="row">
              <div className="col-md-6">
                <div className="form-floating mb-3">
                  <Field
                    type="email"
                    name="email"
                    className="form-control"
                    id="floatingInputemail"
                    placeholder=""
                    value={
                      values?.email ||
                      emailStorage
                    }
                    onChange={(e) => {
                      const value = e.target.value;
                      setEmailStorage(value);
                      setFieldValue("email", value);
                    }}
                  />
                  <label htmlFor="floatingInputemail">Adresse e-mail</label>
                  {touched.email && errors.email && (
                    <div className="text-start mt-8 d-flex">
                      <img src={ErrorIcon} className="mr-8" alt="error icon" />
                      <span className="text-danger">{errors.email}</span>
                    </div>
                  )}
                </div>
              </div>
          
              <div className="col-md-6">
                <div className="form-floating mb-3">
                  <Autocomplete
                    name="address"
                    value={address}
                    searchOptions={searchOptions}
                    onChange={(addr) => handleChangeAddress(addr, "liv", setFieldValue)}
                    onSelect={(addr) => handleSelect(addr, "liv", setFieldValue)}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div className="form-floating">
                        <input
                          {...getInputProps({
                            placeholder: "",
                            className: `form-control ${Error !== "" ? "danger-input" : ""}`
                          })}
                          id="floatingAdr"
                        />
                        <label htmlFor="floatingAdr">Adresse Livraison</label>
                        <div
                          className={
                            suggestions.length > 0 && `container-autocomplete`
                          }
                        >
                          {loading ? <div>Loading...</div> : null}
                          {suggestions.map((suggestion) => {
                            const style = {
                              backgroundColor: suggestion.active
                                ? "#41B6E6"
                                : "#fff",
                            };
                            return (
                              <div
                                className="mapItem"
                                {...getSuggestionItemProps(suggestion, {
                                  style,
                                })}
                              >
                                {suggestion.description}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </Autocomplete>
                  {touched.address && errors.address && (
                    <div className="text-start mt-8 d-flex">
                      <img src={ErrorIcon} className="mr-8" alt="error icon" />
                      <span className="text-danger">{errors.address}</span>
                    </div>
                  )}
                </div>
             </div>
              <div className="col-md-6">
                <div className="form-floating mb-3">
                  <Autocomplete
                    name="pickupAdr"
                    value={pickupAdr}
                    searchOptions={searchOptions}
                    onChange={(addr) => handleChangeAddress(addr, "pickup", setFieldValue)}
                    onSelect={(addr) => handleSelect(addr, "pickup",setFieldValue)}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div className="form-floating">
                        <input
                          {...getInputProps({
                            placeholder: "",
                            className: `form-control ${Error !== "" ? "danger-input" : ""}`
                          })}
                          id="floatingPickupAdr"
                        />
                        <label htmlFor="floatingPickupAdr">Adresse Pick up</label>
                        <div
                          className={
                            suggestions.length > 0 && `container-autocomplete`
                          }
                        >
                          {loading ? <div>Loading...</div> : null}
                          {suggestions.map((suggestion) => {
                            const style = {
                              backgroundColor: suggestion.active
                                ? "#41B6E6"
                                : "#fff",
                            };
                            return (
                              <div
                                className="mapItem"
                                {...getSuggestionItemProps(suggestion, {
                                  style,
                                })}
                              >
                                {suggestion.description}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </Autocomplete>
                  {touched.pickupAdr && errors.pickupAdr && (
                    <div className="text-start mt-8 d-flex">
                      <img src={ErrorIcon} className="mr-8" alt="error icon" />
                      <span className="text-danger">{errors.pickupAdr}</span>
                    </div>
                  )}

                </div>
              </div>
              <div className="col-md-6">
                <div className="form-floating mb-3">
                  <Field
                    type="text"
                    name="steName"
                    className={`form-control ${touched.steName && errors.steName ? "danger-input" : ""}`}
                    id="floatingInputsteName"
                    placeholder=""
                    value={
                      values?.steName ||
                      raisonSocialStorage
                    }
                    onChange={(e) => {
                      const value = e.target.value;
                      setRaisonSocialStorage(value);
                      setFieldValue("steName", value);
                    }}
                  />
                  <label htmlFor="floatingInputsteName">Raison sociale de l’entreprise</label>
                  {touched.steName && errors.steName && (
                    <div className="text-start mt-8 d-flex">
                      <img src={ErrorIcon} className="mr-8" alt="error icon" />
                      <span className="text-danger">{errors.steName}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-floating mb-3">
                  <Field
                    type="text"
                    name="clientName"
                    className={`form-control ${touched.clientName && errors.clientName ? "danger-input" : ""}`}
                    id="floatingInputClientName"
                    placeholder=""
                  />
                  <label htmlFor="floatingInputClientName">Nom client</label>
                  {touched.clientName && errors.clientName && (
                    <div className="text-start mt-8 d-flex">
                      <img src={ErrorIcon} className="mr-8" alt="error icon" />
                      <span className="text-danger">{errors.clientName}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-floating mb-3">
                  <Field
                    type="text"
                    name="steClient"
                    className={`form-control ${touched.steClient && errors.steClient ? "danger-input" : ""}`}
                    id="floatingInputsteClient"
                    placeholder=""
                  />
                  <label htmlFor="floatingInputsteClient">Raison sociale du client</label>
                  {touched.steClient && errors.steClient && (
                    <div className="text-start mt-8 d-flex">
                      <img src={ErrorIcon} className="mr-8" alt="error icon" />
                      <span className="text-danger">{errors.steClient}</span>
                    </div>
                  )}
                </div>
              </div>
             
              <div className="col-md-6">
                <div className="form-floating mb-3">
                  <Field
                    type="text"
                    name="telephone"
                    className={`form-control ${touched.telephone && errors.telephone ? "danger-input" : ""}`}
                    id="floatingTelephone"
                    placeholder=""
                  />
                  <label htmlFor="floatingTelephone">Telephone</label>
                  {touched.telephone && errors.telephone && (
                    <div className="text-start mt-8 d-flex">
                      <img src={ErrorIcon} className="mr-8" alt="error icon" />
                      <span className="text-danger">{errors.telephone}</span>
                    </div>
                  )}
                </div>
              </div>
            
              <div className="col-md-6">
                <div className="form-floating mb-3">
                  <Field
                    type="datetime-local"
                    name="deliveryDateTime"
                    min={currentDate}
                    className={`form-control ${touched.deliveryDateTime && errors.deliveryDateTime ? "danger-input" : ""}`}
                    id="floatingDeliveryDateTime"
                    placeholder=""
                  />
                  <label htmlFor="floatingDeliveryDateTime">Date et Heure de livraison</label>
                  {touched.deliveryDateTime && errors.deliveryDateTime && (
                    <div className="text-start mt-8 d-flex">
                      <img src={ErrorIcon} className="mr-8" alt="error icon" />
                      <span className="text-danger">{errors.deliveryDateTime}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-floating mb-3">
                  <Field
                    as="textarea"
                    name="comment"
                  
                    className="form-control"
                    id="floatingComment"
                    placeholder=""
                  />
                  <label htmlFor="floatingComment">Commentaire</label>
                </div>
              </div>
           
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    id="file"
                    name="deliveryReceipt"
                      accept=".pdf"
                    type="file"
                    onChange={(event) => {
                      setFieldValue("deliveryReceipt", event.currentTarget.files[0]);
                      setFile(event.currentTarget.files[0]);
                    }}
                    className={`form-control ${touched.deliveryReceipt && errors.deliveryReceipt ? "danger-input" : ""}`}
                  />
                  {touched.deliveryReceipt && errors.deliveryReceipt && (
                    <div className="text-start mt-8 d-flex">
                      <img src={ErrorIcon} className="mr-8" alt="error icon" />
                      <span className="text-danger">{errors.deliveryReceipt}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <button type="submit" className="btn btn-primary cta-primary-m" disabled={isSubmitting}>
                  Envoyer
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
    </>
  );
};

export default FormSupplier;
