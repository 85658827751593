import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Card } from "react-bootstrap";
import ClickBack from "../../components/buttons/ClickBack";
import { fetchOneOrder } from "./DataService";
import moment from "moment";
import { urlPdf } from "../../utils/ApiConfig";
import { formatAllType } from '../../utilities';

function ShowScreen(props) {
  const { orderId } = useParams();
  const [orderData, setOrderData] = useState(null);
  const [products, setProducts] = useState([]);

  // Function to fetch order data by ID
  const fetchOrderData = async (orderId) => {
    try {
      const order = await fetchOneOrder(orderId);
      setOrderData(order.data[0]);
      const panierItems = order.data[0].panier.panier_items;
      console.log(panierItems)
      setProducts(panierItems);
    } catch (error) {
      console.error("Error fetching order data:", error);
    }
  };

  useEffect(() => {
    fetchOrderData(orderId);
  }, [orderId]);

  if (!orderData) {
    return (
      <Container>
        <p>Loading...</p>
      </Container>
    );
  }

  // Function to map order status
  const mapStatus = (statusID) => {
    switch (statusID) {
      case "1":
        return "En cours de livraison";
      case "2":
        return "Livré";
      case "3":
        return "En cours de préparation";
      case "4":
        return "En cours de validation";
      case "5":
        return "Validé";
      case "6":
        return "Annulé";
      default:
        return "";
    }
  };

  //Fonction pour définir les status de paiement
  const mapStatusPaiement = (statusID) => {
    switch (statusID) {
      case "2":
        return "Payé";
      default:
        return "Non Payé";
    }
  };

  // Render component
  return (
    <Container>
      <div className="orderDetail">
        <p className="h6">Accueil / Commandes / {orderData.reference}</p>

        <Row className="pt-4 pb-2">
          <Col style={{ display: "flex", alignItems: "center" }}>
            <ClickBack editPage={true} />
            <h1 className="p-1">
              Détails de la commande : {orderData.reference}
            </h1>
          </Col>
        </Row>

        <Card className="mb-3">
          <Card.Body>
            <Card.Title className="pb-3">Détails de la commande</Card.Title>
            <Col className="pb-3">
              <p>
                <strong>Date de commande: </strong>
                {moment(orderData.created_at).format("DD/MM/YYYY , HH:MM")}
              </p>
            </Col>
            <Row className="pb-3">
              <Col>
                <p>
                  <strong>Numéro de commande: </strong>
                  {orderData.reference}
                </p>
              </Col>
              <Col>
                {orderData.user && (
                  <p>
                    <strong>
                      Nom et Prénom :
                      <a
                        className="link-style"
                        href={`/user/show/${orderData.user.id}`}
                      >
                        {orderData.user.nom} {orderData.user.prenom}
                      </a>
                    </strong>
                  </p>
                )}
              </Col>
            </Row>
            <Row className="pb-3">
              <Col>
                <p>
                  <strong>Adresse de livraison: </strong>
                  {orderData?.adresse_user?.adresse}
                </p>
              </Col>
              <Col>
                <p>
                  <strong>Prix total: </strong>
                  {formatAllType(orderData.montant)} €
                </p>
              </Col>
            </Row>
            <Row className="pb-3">
              <Col>
                <p>
                  <strong>Statut de commande: </strong>
                  {mapStatus(orderData.status)}
                </p>
              </Col>
              <Col>
                <p>
                  <strong>Statut de paiement: </strong>
                  {mapStatusPaiement(orderData.etat_paiement)}
                </p>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>

      <Card className="mb-3">
        <Card.Body>
        <Card.Title className="pb-3">Produits:</Card.Title>
          <Row>
            {products.map((item, index) => (
              <Col key={index} md={4}>
                <Card>
                  <Card.Img
                    variant="top"
                    src={`${urlPdf}/${
                      item.product_panier_item.images.find(
                        (image) => image.type === "image"
                      ).path
                    }`}
                    alt={`Product ${index}`}
                  />
                  <Card.Body>
                    <Card.Title>{item.product_panier_item.titre}</Card.Title>
                    <Card.Text>Quantité: {item.quantity}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default ShowScreen;
