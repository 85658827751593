 ///////////////////////////////// Ce fichier pour les fonction qui se répète par tous/////////////////////////////////////////
 
 // Fonction pour formater le nombre avec des séparateurs de milliers et arrondir à 2 chiffres après la virgule
 export const formatAllType = (value) => {
    let valueConvert = Number(value);
    return new Intl.NumberFormat('fr-FR', {  
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(valueConvert);  
  };
  
  
  //Fonction pour la calcule de la montant TTC conçu pour le fournisseur
  export const CalculeTTC = (montantCommValue, htValue, commissionValue, tvaValue) => {
    let commission = (htValue / 100) * commissionValue;
    let htC = commission + htValue + montantCommValue ;
    let tva = (htC * tvaValue) / 100;
    let totalTTC = htC  + tva;
    return totalTTC;
  };
