import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import "bootstrap-icons/font/bootstrap-icons.css";
const ConfirmationModal = ({ show, onClose, onConfirm , actionType}) => {
  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h3>
      {actionType === 'save'
          ? 'Êtes-vous sûr de vouloir de sauvegarder ?'
          : actionType === 'delete'
          ? 'Êtes vous sûr  de vouloir de supprimer ?'
          : actionType === 'add'
          ? 'Êtes vous sûr  de vouloir ajouter un fournisseur ?'
          : ""}
          </h3>
      </Modal.Body>
      <Modal.Footer>
        <a variant="" onClick={onClose} href="#">
        <i className="bi fa-3x bi-arrow-left-square-fill"></i>
        </a>
        <a style={{color:'green'}} onClick={onConfirm}  href="#">
        <i className="bi fa-3x bi-check-square-fill"></i>
        </a>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
