// Import necessary dependencies and components
import React, { useMemo, useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import {
  Row,
  Col,
  Button,
  ButtonGroup,
  Dropdown,
  Badge,
} from "react-bootstrap";
import ClickBack from "../../../components/buttons/ClickBack";
import DynamicTableFournisseur from "../../../components/DynamicTableFournisseur";
import { fetchAllFournisseur, ArchiveFournisseur } from "./DataService";
import IconTrash from "../../../images/trash.png";
import IconShow from "../../../images/fi_eye.png";
import ConfirmationModal from "../../../components/modal/ConfirmationModal";
import OkModal from "../../../components/modal/OkModal";
// Define the functional component
function ListFournisseurs(props) {
  // State to hold user data
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [showOkModal, setShowOkModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [nbr, setNbr] = useState(1);
  const [actionType, setActionType] = useState("");
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [nbElement, setNbElement] = useState(10);

  // Cette fonction pour afficher liste des fournisseurs
  const fetchData = async () => {
    try {
      setLoading(true);
      const data = await fetchAllFournisseur(nbr, searchTerm,nbElement);
      setUserData(data.data);
    } catch (error) {
      console.error("Error lors de affiche liste fournisseur:", error);
    } finally {
      setLoading(false);
    }
  };

  //Cette fonction est pour archiver un fournisseur
  const ArchivageFournisseur = async () => {
    try {
      const response = await ArchiveFournisseur(selectedUserId);
      if (response.status === 200) {
        setShowOkModal(true);
        fetchData(); // Refresh data after deletion
      } else {
        throw new Error(
          `Échec lors d'archivage fournisseur: ${response.status}`
        );
      }
    } catch (error) {
      console.error("Erreur lors de l'archivage fournisseur :", error);
    } finally {
      setShowConfirmationModal(false);
    }
  };

  // Cette fonction pour la confirmation Pop Up
  const handleCloseConfirmationModal = () => {
    setShowConfirmationModal(false);
  };
  //Cette fonction pour fermer le Pop Up
  const handleOkModalClose = () => {
    setShowOkModal(false);
  };
  // Cette fonction pour la confirmation Pop Up lors de l'archivage fournissseur
  const handleDeleteClick = (userId) => {
    setSelectedUserId(userId);
    setActionType("delete");
    setShowConfirmationModal(true);
  };

  // Function to handle user role change
  const handleNombreElementParPage = (nbElement) => {
    setNbElement(nbElement); // Set the selected value
    switch (nbElement) {
      case 10:
        setNbElement(10);
        break;
      case 25:
        setNbElement(25);
        break;
      case 50:
        setNbElement(50);
        break;
      case 100:
        setNbElement(100);
        break;
      default:
        setNbElement(10);
    }
  };
  // useEffect pour charger automatiquement les données lors du montage du composant
  useEffect(() => {
    fetchData(nbr);
  }, [nbr, searchTerm, nbElement]); // Empty dependency array means it will run once when the component mounts

  // Colonnes mémorisées pour le composant DynamicTable
  const columns = useMemo(() => {
    return [
      { Header: "ID", accessor: "id" },
      { Header: "Raison Sociale", accessor: "user_profil.RaisonSocial" },
      { Header: "Adresse pick-up", accessor: "user_profil.adresse" },
      { Header: "E-mail", accessor: "email" },
      {
        Header: "Etat",
        accessor: "status",
        Cell: ({ value }) => (
          <Badge className={value === 1 ? "bg-success" : "bg-danger"}>
            {value === 1 ? "Active" : "Inactive"}
          </Badge>
        ),
      },
      {
        Header: "Actions",
        Cell: ({ value, row }) => (
          <div>
            <a href={`fournisseur/show/${row.original.id}`} className="p-2">
              <img src={IconShow} width="20px" alt="Voir" />
            </a>
            <a
              href="#"
              className="p-2"
              onClick={() => handleDeleteClick(row.original.id)}
            >
              <img src={IconTrash} width="20px" alt="Supprimer" />
            </a>
          </div>
        ),
      },
    ].filter(Boolean); // Filter out any falsey values
  }, [nbr]); // Add dependencies if needed

  // Render loading message while data is being fetched
  if (!userData) {
    return (
      <Container>
        <p>Chargement...</p>
      </Container>
    );
  }

  // Render the component
  return (
    <Container>
      <div className="userList">
        {/* Breadcrumb navigation */}
        <p className="h6">Accueil / Utilisateurs / Fournisseurs</p>

        {/* Header and filters */}
        <Row className="pt-4 pb-2">
          <Col style={{ display: "flex", alignItems: "center" }}>
            <ClickBack />
            {/* Display dynamic title */}
            <h1 className="p-1">Fournisseurs</h1>
          </Col>
          <Col
            xs
            lg="6"
            className="d-flex justify-content-end align-items-center"
            style={{ alignContent: "right" }}
          >
            {/* add button */}
            <Col
              className="d-flex justify-content-end align-items-center"
              style={{ alignContent: "left" }}
            >
              <a className="btn btn-primary" href="/fournisseur/add">
                Ajouter un Fournisseur
              </a>
            </Col>
          </Col>
        </Row>
        {/* Header and filters */}
        <Row className="pt-4 pb-2 align-items-center">
          <Col>
            {/* Display nombre d'element par page dans le dynamic table */}
            <Dropdown as={ButtonGroup} className="p-2 mb-2">
              <Dropdown.Toggle id="dropdown-basic" className="UserTypeDropdown">
               Nombre d'element à afficher: {nbElement} {/* Afficher la valeur actuelle */}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleNombreElementParPage(10)}>
                  10
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleNombreElementParPage(25)}>
                  25
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleNombreElementParPage(50)}>
                  50
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleNombreElementParPage(100)}>
                  100
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col
            xs
            lg="2"
            className="d-flex justify-content-end align-items-center"
            style={{ alignContent: "right" }}
          ></Col>
        </Row>

        {/* Render the DynamicTable component */}
        {loading ? (
          // Display loader while data is being fetched
          <p className="lead">Chargement...</p>
        ) : (
          <DynamicTableFournisseur
            columns={columns}
            setSearchTerm={setSearchTerm}
            data={userData?.data}
            link={userData?.links}
            next_page_url={userData?.next_page_url}
            prev_page_url={userData?.prev_page_url}
            last_page={userData?.last_page}
            setNbr={setNbr}
            nbr={nbr}
            total={userData?.total}
            name="fournisseurs"
          />
        )}
      </div>
      <ConfirmationModal
        show={showConfirmationModal}
        onClose={handleCloseConfirmationModal}
        onConfirm={ArchivageFournisseur}
        actionType={actionType}
      />
      <OkModal
        show={showOkModal}
        onClose={handleOkModalClose}
        onConfirm={handleOkModalClose}
      />
    </Container>
  );
}

// Export the component as the default export
export default ListFournisseurs;
