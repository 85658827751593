import React from "react";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import Dashboard from "./pages/Dashboard/Dashboard";
import NotFound from "./pages/NotFound";
import Layout from "./components/Layout";
//Authentication route
import Log_in from "./pages/Authentication/LoginScreen";
import resetPassword from "./pages/Authentication/ResetPasswordScreen"
import changePassword from "./pages/Authentication/ChangePasswordScreen";

//User route
import ListScreen from "./pages/users/ListScreen";
import EditScreen from "./pages/users/EditScreen"
import ShowScreen from "./pages/users/ShowScreen"
//Products route
import Product_ListScreen from "./pages/Proucts/ListScreen";
import Product_EditScreen from "./pages/Proucts/EditScreen";
import Product_AddScreen from "./pages/Proucts/AddScreen";
//Categories route
import Categories_ListScreen from "./pages/Categories/ListScreen";
//Brands route
import Brands_ListScreen from "./pages/Brands/ListScreen";
//Deliveries route
import Deliveries_ListScreen from "./pages/Deliveries/ListScreen";
import zone_showScreen from "./pages/Deliveries/showScreen"
//Orders route
import Orders_ListScreen from "./pages/orders/ListScreen";
import Orders_showScreen from "./pages/orders/ShowScreen";
//Fournisseur
import fournisseurs_listScreen from "./pages/Fournisseur/CompteFournisseur/ListFournisseurs.jsx"
import fournisseur_showScreen from "./pages/Fournisseur/CompteFournisseur/ShowFournisseur.jsx";
import fournisseur_addScreen  from "./pages/Fournisseur/CompteFournisseur/AddFournisseur.jsx";

//Orders de fournisseur route
import Orders_AllFournisseurs_commandes from "./pages/Fournisseur/CommandesTousFournisseurs/ListCommandesAllFournisseur.jsx";
import Orders_Fournisseurs_ShowScreen from "./pages/Fournisseur/CommandesFournisseurs/ShowCommandeFournisseur";
import Orders_Fournisseurs_EditScreen from "./pages/Fournisseur/CommandesFournisseurs/EditCommandeFournisseur.jsx";
import Orders_Fournisseurs_AddScreen  from "./pages/Fournisseur/CommandesFournisseurs/AddCommandeFournisseur.jsx";
import FirstConnexion from "./pages/Fournisseur/connexionSupplier/firstConnexion.jsx";
//Deliveries route
import DeliveryTower_ListScreen from "./pages/DeliveryTower/ListScreen";
// code Promo route
import CodePromo_ListScreen from "./pages/CodePromo/ListScreen"
//  utilisateur
import User_AddScreen  from "./pages/users/AddScreen"
import FormSupplier from "./pages/Fournisseur/demandeCommandeFournisseurs/orderSupplier.jsx";
import FormSupplierConfirm from "./pages/Fournisseur/demandeCommandeFournisseurs/validationOrder.jsx";
 // toaster
 import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Routes() {
    return (
        
        <BrowserRouter>
          <ToastContainer />

         <Switch>
            
            <Route path="/login" component={Log_in} /> {/* Login route */}      
            <Route path="/fournisseur/renitialise-mot-de-passe/:token" component={FirstConnexion}  /> {/*first connexion fourniseeur */}

            <Route path="/reinitialiser_mot_de_passe" component={resetPassword} /> {/* Reset Password Route */}
            <Route path="/changer_mot_de_passe"  component={changePassword} /> {/* Change Password Route */}
            <Route path="/fournisseur/commande" component={FormSupplier}  /> 
            <Route path="/fournisseur/confirmation" component={FormSupplierConfirm}  /> 
      
            <Route render={(props)=>(
                <Layout {...props}>
                    <Switch>
                        {/* DASHBOARD */}
                        <Route path="/" exact component={Dashboard}/>
                        <Route path="/dashboard" exact component={Dashboard}/>
                        {/* USERS */}
                        <Route path="/users" component={ListScreen}/>
                        <Route path="/user/edit/:id" component={EditScreen} />
                        <Route path="/user/show/:id" component={ShowScreen} />
                        <Route path="/user/add" component={User_AddScreen}  />                    
                        {/* PRODUCTS */}
                        <Route path="/products" component={Product_ListScreen}/>
                        <Route path="/product/edit/:productId" component={Product_EditScreen}/>
                        <Route path="/product/add/" component={Product_AddScreen}/>
                        {/* CATEGORIES */}
                        <Route path="/categories" component={Categories_ListScreen}/>
                        {/* BRANDS */}
                        <Route path="/brands" component={Brands_ListScreen}/>
                        {/* DELIVERIES */}
                        <Route path="/deliveries" component={Deliveries_ListScreen}/>
                        <Route path="/zone/show/:id" component={zone_showScreen}/>
                        {/* ORDERS */}
                        <Route path="/orders" component={Orders_ListScreen}/>
                        <Route path="/order/show/:orderId" component={Orders_showScreen}/>
                        {/* FOURNISSEUR*/}
                        <Route path="/fournisseurs" component={fournisseurs_listScreen} />
                        <Route path="/fournisseur/show/:idFournisseur" component={fournisseur_showScreen}  />
                        <Route path="/fournisseur/add" component={fournisseur_addScreen}  /> 
                        {/* ORDERS FOURNISSEUR */}
                        <Route path="/commandes_Allfournisseur" component={Orders_AllFournisseurs_commandes}/>
                        <Route path="/commande-fournisseur/show/:idCommande" component={Orders_Fournisseurs_ShowScreen}/>
                        <Route path="/commande-fournisseur/edit/:idCommande" component={Orders_Fournisseurs_EditScreen}/>
                        <Route path="/commande-fournisseur/add" component={Orders_Fournisseurs_AddScreen}/>
                        {/* DELIVERY TOWER */}
                        <Route path="/tour" component={DeliveryTower_ListScreen}/>
                        {/* DELIVERY TOWER */}
                        <Route path="/CodePromo" component={CodePromo_ListScreen}/>
                        {/* 404 NOT FOUND */}
                        <Route component={NotFound}/>
                    </Switch>
                </Layout>
            )}/>
            </Switch>
        </BrowserRouter>
    )
}


export default Routes;
